.videoMenuContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px;
    z-index: 10;
    min-width: 190px;
    .textContainer {
        min-width: 180px;
    }
}